@import url('../aux-widgets/styles/blank.css');

:root {

  --aux-main-lineheight: 1em;

  --aux-button-paddinghorizontal: 16px;
  --aux-fader-handleheight: 56px;
  --aux-fader-gridgap: 4px;
  --aux-scale-labelmarginvertical: 2px;
  --aux-scale-minwidth: 28px;
  --aux-meter-size: 4px;
  --aux-meter-gridgap: 4px;
  --aux-state-width: 24px;
  --aux-state-height: 4px;
}


.aux-widget {
  margin: 0;
}


.aux-disabled {
  pointer-events: none;
  opacity: 0.25;
}


.aux-button {
  background: var(--button-background);
  color: var(--button-color);
  border-radius: var(--border-radius);
  transition:
    background var(--transition),
    color var(--transition),
    border var(--transition);
}
.aux-button:hover {
  color: var(--button-hover-indicator);
}
.aux-button.aux-active {
  color: var(--button-active-color);
  background: var(--button-active-background) !important;
}
.aux-button > .aux-icon {
  font-size: var(--font-size-button-icon-small);
}
.aux-button.aux-horizontal.aux-has-icon.aux-has-label > .aux-icon {
  margin-right: 8px;
}
.head .aux-button {
  background: var(--button-background-transparent);
}

.aux-chart {
  background: var(--background-dark);
}
.aux-chart > svg > .aux-grid > .aux-gridline {
  stroke: var(--background-medium);
}
.aux-chart > svg > .aux-grid > .aux-gridlabel {
  fill: var(--color-dark);
}
.aux-chart > svg > .aux-grid > .aux-gridlabel {
  fill: var(--grid-label-color);
}
.aux-chart > svg > .aux-graphs > .aux-graph {
  stroke: var(--graph-color);
}
.aux-chart > svg > .aux-graphs > .aux-steady {
  stroke: var(--color-dark);
}
.aux-chart > svg > .aux-graphs > .aux- {
  stroke: var(--color);
}
.aux-chart > svg > .aux-handles > .aux-charthandle > .aux-handle {
  fill: var(--charthandle-color);
}

.aux-circular {

}
.aux-circular > .aux-base {
  fill: var(--background-dark);
  stroke: var(--background-dark);
  stroke-width: 1px;
}
.aux-circular > .aux-value {
  fill: var(--color-accent);
}
.aux-circular .aux-label {
  fill: var(--color-fade);
}

.aux-fader.aux-horizontal {
/*
  min-width: 256px;
*/
}
.aux-fader.aux-vertical {
/*
  min-height: 256px;
*/
}
.aux-fader.aux-horizontal > .aux-track {
  background: var(--fader-track-horizontal-background);
}
.aux-fader.aux-vertical > .aux-track {
  background: var(--fader-track-vertical-background);
  border-radius: var(--border-radius);
}
.aux-fader > .aux-track > .aux-handle {
  border-radius: var(--border-radius);
}
.aux-fader.aux-horizontal > .aux-track > .aux-handle {
  background: var(--fader-handle-background);
  box-shadow: var(--fader-handle-horizontal-shadow);
}
.aux-fader.aux-vertical > .aux-track > .aux-handle {
  background: var(--fader-handle-vertical-background);
  box-shadow: var(--fader-handle-shadow);
}
.aux-fader > .aux-track > .aux-handle::after {
  content: "";
  position: absolute;
  background: var(--fader-handle-bar);
}
.aux-fader.aux-horizontal > .aux-track > .aux-handle::after {
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
}
.aux-fader.aux-vertical > .aux-track > .aux-handle::after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}
.aux-fader.aux-vertical > .aux-value {
  justify-self: stretch;
}
.aux-fader > .aux-track > .aux-handle:focus {
    outline: 1px dashed var(--color-accent);
    outline-offset: 4px;
}


.aux-fileselect > .aux-filelabel {
  margin-right: 8px;
}
.aux-fileselect > .aux-name {
  padding: 0 8px;
  background: var(--background-dark);
  border: 1px solid var(--background-selected);
  border-right: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  line-height: var(--aux-clickable-size);
  min-width: 128px;
}
.aux-fileselect > .aux-size {
  padding: 0 8px;
  font-size: var(--aux-main-smallfontsize);
  background: var(--background-dark);
  border: 1px solid var(--background-selected);
  border-left: 0;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  line-height: var(--aux-clickable-size);
}

.aux-meter.aux-vertical {
/*
  min-height: 256px;
*/
}
.aux-meter > .aux-bar > .aux-mask {
  opacity: 0.66;
}


.aux-notification {
  background: var(--background-dark);
  display: flex;
}
.aux-notification > .aux-icon {
  font-size: var(--font-size-list-icon);
  margin-right: 8px;
}
.aux-notification > .aux-icon.error {
  color: var(--color-error);
}
.aux-notification > div.content {
  white-space: normal;
  max-width: 256px;
}

.aux-scale > .aux-label {
  color: var(--color-fade);
}

.aux-scroller > .aux-scrollbar::before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 6px;
  bottom: 4px;
  right: 2px;
  border-radius: 2px;
  background: var(--color-fade);
}



.aux-select {
  padding-right: 8px;
  padding-left: 12px;
}
.aux-select:focus {
  outline: 1px dashed var(--color-accent);
  outline-offset: -1px;
}
.aux-button.aux-select > .aux-icon {
  margin-left: 8px !important;
}
.aux-selectlist {
  background: var(--background-dark);
}
.aux-selectlist > .aux-selectentry:hover {
  background: var(--background-medium);
}
.aux-selectlist > .aux-selectentry.aux-active {
  background: var(--color-accent);
  color: var(--color-contrast);
}
.aux-selectlist > .aux-selectentry:focus {
  outline: 1px dashed var(--color);
  outline-offset: -1px;
}
.aux-selectlist > .aux-selectentry.aux-active:focus {
  outline-color: var(--color);
}



.aux-state {
  background: var(--color-ok);
  border-radius: 2px;
}
.aux-state > .aux-mask {
  background: var(--background-dark);
}


.aux-button.aux-toggle {
  background: var(--toggle-background);
  color: var(--toggle-color);
}
.aux-button.aux-toggle:hover {
  background: var(--toggle-hover-background);
  color: var(--toggle-hover-color);
}
.aux-button.aux-toggle.aux-active {
  background: var(--toggle-hover-background) !important;
  color: var(--toggle-hover-color);
}
.aux-button.aux-toggle.aux-active > .aux-icon {
  color: var(--color-accent);
}
.aux-button:focus {
  outline: 1px dashed var(--color-accent);
  outline-offset: -1px;
}

.selected > .head > .aux-button:focus,
.selected > .head > .aux-button:focus {
  outline: 1px dashed var(--color);
  outline-offset: -1px;
}
.aux-value {

}
.aux-value > .aux-input {
  background: var(--background-dark);
  color: var(--color);
  font-size: var(--font-size-large);
  border: 1px solid var(--background-selected);
  border-radius: var(--border-radius);
}
.aux-valueknob > .aux-value > .aux-input,
.aux-fader > .aux-value > .aux-input {
  background: transparent;
  color: var(--color);
  font-size: var(--font-size-large);
  border: none;
}

.aux-valueknob .aux-knob > svg:focus {
  outline: 1px dashed var(--color-accent);
  outline-offset: -1px;
}
