html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
tr:nth-child(odd) {
  background: var(--background-row-odd);
}
tr:nth-child(even) {
  background: var(--background-row-even);
}
td {
  padding: 5px;
}

hr {
  border: 1px inset var(--color-fade);
  margin: 32px;
}

a {
  color: var(--link-color);
}
a:hover {
  color: var(--link-hover-color);
}
a:visited {
  color: var(--link-visited-color);
}

* {
  position: relative;
}

body,
html {
  background: var(--background) !important;
  color: var(--color) !important;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: PragatiNarrow, sans-serif;
  font-weight: 400;
  font-size: var(--font-size);
  line-height: var(--line-height);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  min-width: 480px;
  min-height: 400px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  --aes70-explorer-icon-4c-white: url(/htdocs/images/aes70explorer_4c_white.svg);
}



#root {
  display: grid;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  grid-template-columns: auto 1fr auto;
  grid-template-rows: 48px 1fr auto;
  grid-template-areas:
      "header header header"
      "navigation canvas sidebar"
      "navigation details details";
}

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-medium);
  z-index: 1000000;
}
#loading1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 40px;
  background-image: url(../images/aes70explorer_4c_white.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
#loading2 {
  position: absolute;
  bottom: -1px;
  width: 20px;
  height: 1px;
  background: white;
  animation: loading 3s linear infinite;
}
@keyframes loading {
  0%   { left: 0; }
  50%  { left: calc(100% - 20px); }
  100% { left: 0; }
}

/*
#screensize {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-content: center;
  background: var(--background-medium);
  z-index: 1000000;
}
#screensize > .aux-icon {
  font-size: 40px;
  color: var(--color-accent);
}
@media (max-width: 1023px), (max-height: 670px) {
  #screensize { display: grid; }
}
*/

/* TIPS */

#tipsclone {
  position: absolute;
}
#tips {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-medium);
  z-index: 100000;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 16px 32px;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 16px;
}

#tips > * {
  white-space: normal;
  max-width: 256px;
}

#tips > .copyright {
  text-align: right;
}
#tips > .copyright > a {
  color: var(--color) !important;
  text-decoration: none !important;
  opacity: 0.2;
}
#tips > .copyright img {
  width: 96px;
  margin-top: 4px;
}

#tips > div > .aux-icon {
  font-size: 48px;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}
#tips h2 {
  font-size: var(--font-size-huge);
  margin-bottom: 8px;
  color: var(--color-accent);
}

#tips > .aux-button {
  justify-self: center;
  align-self: center;
  padding: 32px;
  width: 160px;
  animation: closehelp 0.75s linear alternate infinite;
  border: 1px solid;
}
@keyframes closehelp {
  0% { color: var(--color); border-color: var(--color); }
  100% { color: var(--color-accent); border-color: var(--color-accent); }
}
#tips > .aux-button > .aux-icon {
  font-size: 20px;
  margin: 0;
  margin-right: 6px;
}
#tips > .aux-button > .aux-label {
  font-size: var(--font-size-large);
}

/* HEADER */

#header {
  grid-area: header;
  display: flex;
  /* justify-content: flex-end; */
  padding: 1px;
  padding-left: 300px;

  background-color: var(--background-fade);
  background-image: url("../images/aes70explorer_4c_white.svg");
  background-size: 100px auto;
  background-repeat: no-repeat;
  background-position: 12px center;
}
#header > * {
  flex: 0 1 auto;
}

#header .aux-button {
  height: auto;
  white-space: normal;
  background: var(--background-medium);
  margin: 1px;
  padding: var(--aux-button-paddingvertical) 12px;
}
#header .aux-button > .aux-icon {
  font-size: 24px;
}

#header > #interface {
  width: 240px;
  height: 100%;
}
#header > #interface > .aux-button {
  height: 100%;
}

#header > .spacer {
  flex: 1 1 auto;
}

#header > .separator {
  width: 1px;
  margin: 4px 16px;
  background: var(--color-fade);
}

@media (max-width: 1280px) {
  #header .aux-button > .aux-label {
    display: none;
  }
  #header .aux-button > .aux-icon {
    margin-right: 0;
  }
}

/* SIDEBAR */

#sidebar {
  grid-area: sidebar;
  background-color: var(--background-fade);
  display: flex;
  flex-direction: column;
  transition: all var(--transition);
  width: 240px;
  padding-top: 32px;
}
#sidebar.hide {
  width: 0px;
}
#sidebar > .aux-button {
  margin: 1px;
}
#sidebar > .separator {
  height: 32px;
}

/* CANVAS */

#canvas {
  grid-area: canvas;
  overflow: auto;
  display: flex;
  align-items: safe center;
  justify-content: safe center;
  flex-wrap: wrap;
  padding: 8px;
}


/* DETAILS */

#details {
  height: 300px;
  overflow: hidden;
  grid-area: details;
  display: grid;
  grid-template-areas: "head control"
                       "details control";
  grid-template-rows: auto 1fr;
  grid-template-columns: 5fr 2fr;
  grid-gap: 8px 8px;
  padding: 8px 16px;
  background: var(--background-fade);
  border-top: 1px solid var(--color-accent);
  transition: all var(--transition);
}
#details.hide {
  height: 40px;
}
#details.block,
#details.device {
  grid-template-areas: "head"
                       "details";
  grid-template-columns: 100%;
}
#details > .head {
  grid-area: head;
  display: grid;
  grid-template-areas: "icon title id class docs"
                       "icon path path path path";
  grid-template-columns: auto auto auto auto 1fr;
  grid-gap: 4px;
  text-align: left;
}
#details > .head * {
  margin: 0;
}
#details > .head > .icon {
  grid-area: icon;
  font-size: 40px;
}
#details > .head > .role {
  grid-area: title;
  font-size: var(--font-size-huge);
  line-height: 1;
  align-self: end;
}
#details > .head > .classid {
  grid-area: id;
  font-size: var(--font-size-small);
  align-self: end;
  color: var(--color-fade);
}
#details > .head > .classname {
  grid-area: class;
  font-size: var(--font-size-small);
  align-self: end;
  color: var(--color-fade);
}
#details > .head > .docs {
  grid-area: docs;
  text-decoration: none;
  align-self: end;
}
#details > .head > .docs > .aux-icon {
  font-size: var(--font-size-large);
  color: var(--color-fade);
  transition: color var(--transition);
}
#details > .head > .docs:hover > .aux-icon {
  color: var(--color);
}
#details > .head > .path {
  grid-area: path;
  font-size: 2em;
}

#details > .control {
  grid-area: control;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
#details.hide > .control,
#details.block > .control,
#details.device > .control {
  display: none;
}
#details > .control > * {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
#details > .details {
  grid-area: details;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}
#details.block > .details,
#details.device > .details {
  justify-items: stretch;
}

#details > .details > * {
  margin: 0 8px;
  flex: 1 1 auto;
}
#details > .details .grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 3px 6px;
  align-content: center;
  align-items: center;
  margin: auto 8px;
}
#details > .details .grid > * {
  margin: 0;
}
#details > .details .grid > .label {
  text-align: right;
  display: block;
  color: var(--color-fade);
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
#details > .details .grid > .aux-widget {
  max-width: 256px;
}
#details > .details .grid > .aux-value {
  width: 200px;
}
#details > .details .grid > .aux-toggle {
  width: auto;
  justify-self: start;
  height: 32px;
  width: fit-content;
}
#details > .details .grid > .aux-toggle > .aux-icon {
  font-size: 18px;
  flex: 1 0 auto;
}

#details > .noselect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  opacity: 0.1;
  font-size: var(--font-size-huge);
}

#details .aux-label {
  user-select: text;
}

/* NAVIGATION */

aes70-navigation {
  grid-area: navigation;
  width: 300px;
  height: 100%;
  background: var(--background-dark);
  text-align: left;
  overflow: hidden;
  transition: width var(--transition);
  display: flex;
  flex-direction: column;
  order: 0;
}
aes70-navigation.hide {
  width: 0px;
}
aes70-navigation > .list {
  flex: 1 1 auto;
  overflow: hidden;
}
aes70-navigation > .list > .aux-scroller {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}

aes70-navigation .head {
  height: 40px;
  display: grid;
  grid-template-areas:
    "icon label";
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid var(--background);
  cursor: pointer;
  user-select: none;
}
aes70-navigation .aux-label {
  margin: 0;
  padding: 0 4px;
  align-items: center;
  align-self: center;
  grid-area: label;
}
aes70-navigation .icon {
  grid-area: icon;
  font-size: var(--font-size-list-icon);
  margin: 0px;
  grid-area: icon;
}
aes70-navigation > aes70-add-device {
  flex: 0 0 auto;
  order: 1;
  overflow: hidden;
  background: var(--background-medium);
}
aes70-navigation > aes70-add-device .aux-button {
  background: var(--background-medium);
}
aes70-navigation > aes70-add-device > .add {
  display: flex;
}
aes70-navigation > aes70-add-device > .container {
  display: grid;
  grid-gap: 4px;
  grid-template-areas:
    "lurl lport"
    "url port"
    "ok cancel";
  grid-template-columns: 1fr 100px;
  padding: 0px;
  height: 0px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all var(--transition);
}
aes70-navigation > aes70-add-device > .container.open {
  height: 96px;
  padding: 4px;
}
aes70-navigation  > aes70-add-device > .container > .lurl { grid-area: lurl; font-size: var(--font-size-small);}
aes70-navigation  > aes70-add-device > .container > .lport { grid-area: lport; font-size: var(--font-size-small); }
aes70-navigation  > aes70-add-device > .container > .url { grid-area: url; }
aes70-navigation  > aes70-add-device > .container > .port { grid-area: port; }
aes70-navigation  > aes70-add-device > .container > .ok { grid-area: ok; }
aes70-navigation  > aes70-add-device > .container > .cancel { grid-area: cancel; }
aes70-navigation  > aes70-add-device > .container > .ok > .aux-icon { color: var(--color-ok); }
aes70-navigation  > aes70-add-device > .container > .cancel > .aux-icon { color: var(--color-warn); }


aes70-navigation > .list > .nodevice {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  width: 80%;
  text-transform: uppercase;
  opacity: 0.1;
  font-size: var(--font-size-huge);
  text-align: center;
}

/* DEVICE */

aes70-device {

}
aes70-device > .head {
  display: grid;
  grid-template-areas: 'icon name ihost host remove action'
                       'icon net  iport port remove action';
  background: var(--background);
  grid-template-columns: 40px 1fr auto auto;
  grid-gap: 1px 4px;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all var(--transition);
}
aes70-device.selected > .head {
  background: var(--color-accent);
}
aes70-device > .head * {
  color: var(--color) !important;
}
aes70-device.selected > .head * {
  color: var(--color-contrast) !important;
  transition: all var(--transition);
}
aes70-device > .head.disconnected * {
  color: var(--color-fade) !important;
}
aes70-device.selected > .head.disconnected {
  background: var(--background-selected) !important;
}
aes70-device > .head > .aux-button .aux-icon {
  font-size: var(--font-size-button-icon-small);
}
aes70-device > .head > .icon,
aes70-device > .head > .aux-button:first-child > .aux-icon {
  font-size: 32px;
}
aes70-device.aes70-open > .head > .icon {
  color: var(--color);
}
aes70-device > .head > .name {
  grid-area: name;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-large);
  align-self: end;
}
aes70-device > .head > .net {
  grid-area: net;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-tiny);
  align-self: start;
}
aes70-device > .head > .host,
aes70-device > .head > .port {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  height: auto;
  justify-self: end;
  padding: 0;
}
aes70-device > .head > .host {
  grid-area: host;
  align-self: end;
  justify-self: start;
}
aes70-device > .head > .port {
  grid-area: port;
  align-self: start;
  justify-self: start;
}
aes70-device > .head > .ihost,
aes70-device > .head > .iport {
  font-size: var(--font-size-small);
  margin: 0;
}
aes70-device > .head > .ihost {
  grid-area: ihost;
  align-self: end;
}
aes70-device > .head > .iport {
  grid-area: iport;
  align-self: start;
}
aes70-device > .head > .aux-button {
  background: none !important;
  margin: 0;
  padding: 0 4px;
  height: auto;
}
aes70-device > .head > .addcontrol,
aes70-device > .head > .removecontrol {
  grid-area: action;
}
aes70-device > .head > .addcontrol {
  color: var(--color-fade);
}
aes70-device > .head > .removedevice {
  grid-area: remove;
}
aes70-device > .head > .removedevice:hover,
aes70-device > .head > .removecontrol:hover {
  color: var(--color-accent);
}

/* BLOCK CHILDREN */

aes70-block-children {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
}
aes70-block-children > .loading {
  height: 36px;
  overflow: hidden;
  display: block;
  position: relative;
  order: 1000000;
}


aes70-block-children > .loading {
  --loading-delay: 0.25s;
  --loading-distance: 16px;
  --loading-items: 5;
  --loading-size: 2px;
  --loading-scale: 3;
  --loading-color-0: var(--color);
  --loading-color-1: var(--color);
  --loading-opacity-0: 1;
  --loading-opacity-1: 1;
}
aes70-block-children > .loading > i {
  position: absolute;
  top: calc(50% - var(--loading-size) / 2);

  display: block;
  width: var(--loading-size);
  height: var(--loading-size);

  border-radius: 50%;
  background: white;
  animation: loading 1.5s infinite ease-in-out;
}

aes70-block-children > .loading > i:nth-child(1) {
  animation-delay: calc(var(--loading-delay) * 0);
  left: calc(50% + var(--loading-size) / -2 + var(--loading-distance) * 0 - var(--loading-items) / 2 * var(--loading-distance));
}
aes70-block-children > .loading > i:nth-child(2) {
  animation-delay: calc(var(--loading-delay) * 1);
  left: calc(50% + var(--loading-size) / -2 + var(--loading-distance) * 1 - var(--loading-items) / 2 * var(--loading-distance));
}
aes70-block-children > .loading > i:nth-child(3) {
  animation-delay: calc(var(--loading-delay) * 2);
  left: calc(50% + var(--loading-size) / -2 + var(--loading-distance) * 2 - var(--loading-items) / 2 * var(--loading-distance));
}
aes70-block-children > .loading > i:nth-child(4) {
  animation-delay: calc(var(--loading-delay) * 3);
  left: calc(50% + var(--loading-size) / -2 + var(--loading-distance) * 3 - var(--loading-items) / 2 * var(--loading-distance));
}
aes70-block-children > .loading > i:nth-child(5) {
  animation-delay: calc(var(--loading-delay) * 4);
  left: calc(50% + var(--loading-size) / -2 + var(--loading-distance) * 4 - var(--loading-items) / 2 * var(--loading-distance));
}

@keyframes loading {
  0% {
    transform: scale(1);
    background: var(--loading-color-0);
    opacity: var(--loading-opacity-0);
  }
  50% {
    transform: scale(var(--loading-scale));
    background: var(--loading-color-1);
    opacity: var(--loading-opacity-1);
  }
  100% {
    transform: scale(1);
    background: var(--loading-color-0);
    opacity: var(--loading-opacity-0);
  }
}

/* EMPTY */

aes70-empty {
  display: block;
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  opacity: 0.25;
  font-size: var(--font-size-huge);
}


/* BLOCK */

aes70-block {
  display: block;
}
aes70-block > .head {
  background: var(--background-medium);
  border-bottom: 1px solid black;
  transition: all var(--transition);
  grid-template-areas: "icon label iconm members";
  grid-template-columns: 40px 1fr auto;
}
aes70-block.selected > .head {
  background: var(--color-accent);
}
aes70-block > .head * {
  color: var(--color) !important;
}
aes70-block.selected > .head * {
  color: var(--color-contrast) !important;
  transition: all var(--transition);
}
aes70-block > .head > .label {

}
aes70-block > .head > .iconm {
  grid-area: iconm;
  font-size: var(--font-size-medium);
}
aes70-block > .head > .children {
  grid-area: members;
  font-size: var(--font-size-medium);
}
aes70-block > .head > .icon {
  color: var(--color-fade);
  transition: color var(--transition);
}
aes70-block.aes70-open > .head > .icon {
  color: var(--color);
}

/* OBJECT */

aes70-object > .head {
  display: grid;
  grid-template-areas:
    "icon label action"
    "icon class action";
  transition: all var(--transition);
}
aes70-object:last-of-type > .head {
  border-bottom: none;
}
aes70-object.selected > .head {
  background: var(--color-accent);
}
aes70-object > .head * {
  color: var(--color) !important;
  transition: all var(--transition);
}
aes70-object.selected > .head * {
  color: var(--color-contrast) !important;
  transition: all var(--transition);
}
aes70-object.selected.hascontrol > .head > .label {
  color: var(--color) !important;
}

aes70-object > .head > .icon {
  grid-area: icon;
}
aes70-object > .head > .label {
  align-self: end;
}
aes70-object.hascontrol > .head > .label {
  color: var(--color-accent) !important;
}
aes70-object > .head > .class {
  grid-area: class;
  font-size: var(--font-size-tiny);
  opacity: 0.5;
  align-self: start;
}
aes70-object > .head > .aux-button {
  background: none;
  margin: 0;
  padding: 0 4px;
  height: auto;
}
aes70-object > .head > .aux-button > .aux-icon {
  font-size: var(--font-size-button-icon-small);
}
aes70-object > .head > .add,
aes70-object > .head > .remove {
  grid-area: action;
}
aes70-object > .head > .add {
  color: var(--color-fade);
}
aes70-object > .head > .remove {

}
aes70-object > .head > .remove:hover {
  color: var(--color-accent);
}



aes70-details-block,
aes70-details-devicemanager {
  width: 100%;
}
aes70-details-block > *,
aes70-details-devicemanager > * {
  flex: 1 1 auto;
}
