@font-face {
  font-family: "AES70Browser";
  font-style: normal;
  font-weight: normal;
  src: url("AES70Browser.ttf") format("truetype");
}

.aux-icon.ipaddress::before                 { font-family: 'AES70Browser'; content: '񗍄'; }
.aux-icon.ip::before                        { font-family: 'AES70Browser'; content: '񗍄'; }
.aux-icon.muted::before                     { font-family: 'AES70Browser'; content: '񗍅'; }
.aux-icon.unmuted::before                   { font-family: 'AES70Browser'; content: '񗍆'; }
.aux-icon.polarity::before                  { font-family: 'AES70Browser'; content: '񗍇'; }
.aux-icon.phase::before                     { font-family: 'AES70Browser'; content: '񗍇'; }
.aux-icon.edit::before                      { font-family: 'AES70Browser'; content: '񗍈'; }
.aux-icon.port::before                      { font-family: 'AES70Browser'; content: '񗍉'; }
.aux-icon.puzzle::before                    { font-family: 'AES70Browser'; content: '񗍊'; }
.aux-icon.trash::before                     { font-family: 'AES70Browser'; content: '񗍋'; }
.aux-icon.confirm::before                   { font-family: 'AES70Browser'; content: '񗍌'; }
.aux-icon.kill::before                      { font-family: 'AES70Browser'; content: '񗍍'; }
.aux-icon.book::before                      { font-family: 'AES70Browser'; content: '񗍎'; }
.aux-icon.documentation::before             { font-family: 'AES70Browser'; content: '񗍎'; }
.aux-icon.manual::before                    { font-family: 'AES70Browser'; content: '񗍎'; }
.aux-icon.addlinebreak::before              { font-family: 'AES70Browser'; content: '񗍏'; }
.aux-icon.deletelinebreak::before           { font-family: 'AES70Browser'; content: '񗍐'; }
.aux-icon.savecontrols::before              { font-family: 'AES70Browser'; content: '񗍑'; }
.aux-icon.wipecontrols::before              { font-family: 'AES70Browser'; content: '񗍒'; }
.aux-icon.hipass::before                    { font-family: 'AES70Browser'; content: '񗍓'; }
.aux-icon.lopass::before                    { font-family: 'AES70Browser'; content: '񗍔'; }
.aux-icon.bandpass::before                  { font-family: 'AES70Browser'; content: '񗍕'; }
.aux-icon.bandreject::before                { font-family: 'AES70Browser'; content: '񗍖'; }
.aux-icon.allpass::before                   { font-family: 'AES70Browser'; content: '񗍗'; }
.aux-icon.enabled::before                   { font-family: 'AES70Browser'; content: '񗍘'; }
.aux-icon.item::before                      { font-family: 'AES70Browser'; content: '񗍙'; }
.aux-icon.sine::before                      { font-family: 'AES70Browser'; content: '񗍚'; }
.aux-icon.dc::before                        { font-family: 'AES70Browser'; content: '񗍛'; }
.aux-icon.square::before                    { font-family: 'AES70Browser'; content: '񗍜'; }
.aux-icon.impulse::before                   { font-family: 'AES70Browser'; content: '񗍝'; }
.aux-icon.pinknoise::before                 { font-family: 'AES70Browser'; content: '񗍞'; }
.aux-icon.whitenoise::before                { font-family: 'AES70Browser'; content: '񗍟'; }
.aux-icon.polaritytest::before              { font-family: 'AES70Browser'; content: '񗍠'; }
.aux-icon.stop::before                      { font-family: 'AES70Browser'; content: '񗍡'; }
.aux-icon.play::before                      { font-family: 'AES70Browser'; content: '񗍢'; }
.aux-icon.list::before                      { font-family: 'AES70Browser'; content: '񗍣'; }
.aux-icon.details::before                   { font-family: 'AES70Browser'; content: '񗍤'; }
.aux-icon.plus::before                      { font-family: 'AES70Browser'; content: '񗍥'; }
.aux-icon.cancel::before                    { font-family: 'AES70Browser'; content: '񗍦'; }
.aux-icon.ok::before                        { font-family: 'AES70Browser'; content: '񗍧'; }
.aux-icon.openfolder::before                { font-family: 'AES70Browser'; content: '񗍨'; }
.aux-icon.folder::before                    { font-family: 'AES70Browser'; content: '񗍨'; }
.aux-icon.gear::before                      { font-family: 'AES70Browser'; content: '񗍩'; }
.aux-icon.refresh::before                   { font-family: 'AES70Browser'; content: '񗍪'; }
.aux-icon.reload::before                    { font-family: 'AES70Browser'; content: '񗍪'; }
.aux-icon.recycle::before                   { font-family: 'AES70Browser'; content: '񗍪'; }
