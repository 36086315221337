/*
 * This file is part of AUX.
 *
 * AUX is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * AUX is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General
 * Public License along with this program; if not, write to the
 * Free Software Foundation, Inc., 51 Franklin Street, Fifth Floor,
 * Boston, MA  02110-1301  USA
 */

/*
 * This is a very basic AUX theme, only containing basic lengths and sizes.
 * Use it as a basis to build your custom interface design. This file
 * includes all fonts needed for building basic interfaces, not including
 * special variants like bold or italic.
 */

@charset "UTF-8";

@import './essentials.css';
@import './basics.css';

@import './fonts/Oswald-Regular.css';
@import './fonts/PragatiNarrow-Regular.css';

@import './fonts/AUXIcons.css';
@import './fonts/AUXShapes.css';
