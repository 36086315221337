@font-face {
  font-family: "AES70";
  font-style: normal;
  font-weight: normal;
  src: url("AES70.ttf") format("truetype");
}

.aux-icon.ocaroot::before                    { font-family: 'AES70'; content: '񗝄'; }
.aux-icon.ocaworker::before                  { font-family: 'AES70'; content: '񗝅'; }
.aux-icon.ocaactuator::before                { font-family: 'AES70'; content: '񗝆'; }
.aux-icon.ocabasicactuator::before           { font-family: 'AES70'; content: '񗝇'; }
.aux-icon.ocabooleanactuator::before         { font-family: 'AES70'; content: '񗝈'; }
.aux-icon.ocaint8actuator::before            { font-family: 'AES70'; content: '񗝉'; }
.aux-icon.ocaint16actuator::before           { font-family: 'AES70'; content: '񗝊'; }
.aux-icon.ocaint32actuator::before           { font-family: 'AES70'; content: '񗝋'; }
.aux-icon.ocaint64actuator::before           { font-family: 'AES70'; content: '񗝌'; }
.aux-icon.ocauint8actuator::before           { font-family: 'AES70'; content: '񗝍'; }
.aux-icon.ocauint16actuator::before          { font-family: 'AES70'; content: '񗝎'; }
.aux-icon.ocauint32actuator::before          { font-family: 'AES70'; content: '񗝏'; }
.aux-icon.ocauint64actuator::before          { font-family: 'AES70'; content: '񗝐'; }
.aux-icon.ocafloat32actuator::before         { font-family: 'AES70'; content: '񗝑'; }
.aux-icon.ocafloat64actuator::before         { font-family: 'AES70'; content: '񗝒'; }
.aux-icon.ocastringactuator::before          { font-family: 'AES70'; content: '񗝓'; }
.aux-icon.ocabitstringactuator::before       { font-family: 'AES70'; content: '񗝔'; }
.aux-icon.ocamute::before                    { font-family: 'AES70'; content: '񗝕'; }
.aux-icon.ocapolarity::before                { font-family: 'AES70'; content: '񗝖'; }
.aux-icon.ocaswitch::before                  { font-family: 'AES70'; content: '񗝗'; }
.aux-icon.ocagain::before                    { font-family: 'AES70'; content: '񗝘'; }
.aux-icon.ocapanbalance::before              { font-family: 'AES70'; content: '񗝙'; }
.aux-icon.ocadelay::before                   { font-family: 'AES70'; content: '񗝚'; }
.aux-icon.ocadelayextended::before           { font-family: 'AES70'; content: '񗝛'; }
.aux-icon.ocafrequencyactuator::before       { font-family: 'AES70'; content: '񗝜'; }
.aux-icon.ocafilterclassical::before         { font-family: 'AES70'; content: '񗝝'; }
.aux-icon.ocafilterparametric::before        { font-family: 'AES70'; content: '񗝞'; }
.aux-icon.ocafilterpolynomial::before        { font-family: 'AES70'; content: '񗝟'; }
.aux-icon.ocafilterfir::before               { font-family: 'AES70'; content: '񗝠'; }
.aux-icon.ocadynamics::before                { font-family: 'AES70'; content: '񗝢'; }
.aux-icon.ocadynamicsdetector::before        { font-family: 'AES70'; content: '񗝣'; }
.aux-icon.ocadynamicscurve::before           { font-family: 'AES70'; content: '񗝤'; }
.aux-icon.ocasignalgenerator::before         { font-family: 'AES70'; content: '񗝥'; }
.aux-icon.ocatemperatureactuator::before     { font-family: 'AES70'; content: '񗝨'; }
.aux-icon.ocaidentificationactuator::before  { font-family: 'AES70'; content: '񗝩'; }
.aux-icon.ocasummingpoint::before            { font-family: 'AES70'; content: '񗝪'; }
.aux-icon.ocasensor::before                  { font-family: 'AES70'; content: '񗝫'; }
.aux-icon.ocabooleansensor::before           { font-family: 'AES70'; content: '񗝭'; }
.aux-icon.ocaint8sensor::before              { font-family: 'AES70'; content: '񗝮'; }
.aux-icon.ocaint16sensor::before             { font-family: 'AES70'; content: '񗝯'; }
.aux-icon.ocaint32sensor::before             { font-family: 'AES70'; content: '񗝰'; }
.aux-icon.ocaint64sensor::before             { font-family: 'AES70'; content: '񗝱'; }
.aux-icon.ocauint8sensor::before             { font-family: 'AES70'; content: '񗝲'; }
.aux-icon.ocauint16sensor::before            { font-family: 'AES70'; content: '񗝳'; }
.aux-icon.ocauint32sensor::before            { font-family: 'AES70'; content: '񗝴'; }
.aux-icon.ocauint64sensor::before            { font-family: 'AES70'; content: '񗝵'; }
.aux-icon.ocafloat32sensor::before           { font-family: 'AES70'; content: '񗝶'; }
.aux-icon.ocafloat64sensor::before           { font-family: 'AES70'; content: '񗝷'; }
.aux-icon.ocastringsensor::before            { font-family: 'AES70'; content: '񗝸'; }
.aux-icon.ocabitstringsensor::before         { font-family: 'AES70'; content: '񗝹'; }
.aux-icon.ocalevelsensor::before             { font-family: 'AES70'; content: '񗝺'; }
.aux-icon.ocatimeintervalsensor::before      { font-family: 'AES70'; content: '񗝼'; }
.aux-icon.ocafrequencysensor::before         { font-family: 'AES70'; content: '񗝽'; }
.aux-icon.ocatemperaturesensor::before       { font-family: 'AES70'; content: '񗝾'; }
.aux-icon.ocavoltagesensor::before           { font-family: 'AES70'; content: '񗞀'; }
.aux-icon.ocacurrentsensor::before           { font-family: 'AES70'; content: '񗞁'; }
.aux-icon.ocaimpedancesensor::before         { font-family: 'AES70'; content: '񗞂'; }
.aux-icon.ocagainsensor::before              { font-family: 'AES70'; content: '񗞃'; }
.aux-icon.ocablock::before                   { font-family: 'AES70'; content: '񗞄'; }
.aux-icon.ocanetwork::before                 { font-family: 'AES70'; content: '񗞉'; }
.aux-icon.ocamediaclock3::before             { font-family: 'AES70'; content: '񗞔'; }
.aux-icon.ocamanager::before                 { font-family: 'AES70'; content: '񗞗'; }
.aux-icon.ocaaudioprocessingmanager::before  { font-family: 'AES70'; content: '񗞠'; }
.aux-icon.ocadiagnosticmanager::before       { font-family: 'AES70'; content: '񗞤'; }
.aux-icon.ocadevicemanager::before           { font-family: 'AES70'; content: '񗞩'; }
.aux-icon.ocadevice::before                  { font-family: 'AES70'; content: '񗞩'; }
.aux-icon.ocablockopen::before               { font-family: 'AES70'; content: '񗞪'; }
.aux-icon.ocadeviceopen::before              { font-family: 'AES70'; content: '񗞫'; }
