aes70-line-break {
  flex-basis: 100%;
  margin: 16px 0px;
  border-top: 4px dotted rgba(255,255,255,0.06);
}

aes70-control {
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px;
  box-shadow: 0 0 0px var(--color);
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0px var(--background);
  transition: background var(--transition),
              color var(--transition),
              box-shadow var(--transition);
  background: var(--background-control);
  margin: 4px;
  border: 1px solid var(--background-control);
}

aes70-control.selected {
  z-index: 10;
  background: var(--background-selected);
  border: var(--border-control-selected);
  animation: aes70-control-selected var(--transition);
}
@keyframes aes70-control-selected {
  0% { border-color: white; };
  100% { border-color:  var(--bordercolor-control-selected) };
}

aes70-control.scaffold::before {
  content: "񗍍";
  font-size: 40px;
  font-family: AES70Browser;
  color: var(--color-accent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  line-height: 40px;
  animation: scaffold infinite 1s;
  z-index: 20;
}
aes70-control.scaffold > * {
  opacity: 0.5;
  filter: blur(3px);
}
aes70-control.scaffold.selected {
  box-shadow: 0 0 4px var(--color-accent);
  background: var(--background-medium);
}

@keyframes scaffold {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

aes70-control > * {
  display: block;
  transition: opacity var(--transition);
}



.control .aux-button.edit,
aes70-control .aux-button.edit {
  background: none;
  color: var(--color-fade);
  padding: 4px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
.control .aux-button.edit > .aux-icon,
aes70-control .aux-button.edit > .aux-icon {
  font-size: var(--font-size-button-icon);
}


.control .aux-toggle,
aes70-control .aux-toggle {
  padding-left: 4px;
}
.control .aux-toggle::before,
aes70-control .aux-toggle::before {
  content: "";
  background: var(--toggle-indicator);
  transition: all var(--transition);
  width: 3px;
  height: 100%;
  border-radius: 2px;
  margin-right: var(--aux-button-paddinghorizontal);
}
.control .aux-toggle:hover::before,
aes70-control .aux-toggle:hover::before {
  background: var(--toggle-hover-indicator);
}
.control .aux-toggle.aux-active::before,
aes70-control .aux-toggle.aux-active::before {
  background: var(--toggle-active-indicator);
}


.control .aux-valueknob,
aes70-control .aux-valueknob {
  margin-bottom: var(--line-height);
}
.control .aux-valueknob > .aux-value,
aes70-control .aux-valueknob > .aux-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: calc(var(--line-height) / 2 - 4px);
}

.control .aux-valueknob.small > .aux-value > .aux-input,
aes70-control .aux-valueknob.small > .aux-value > .aux-input {
  font-size: var(--font-size);
}
.control .aux-valueknob > .aux-knob,
aes70-control .aux-valueknob > .aux-knob {
  margin-top: -8px;
}

.control .aux-buttons > .aux-button,
aes70-control .aux-buttons > .aux-button {
  padding: 0;
  margin: 0 1px;
  width: var(--aux-clickable-size);
}


aes70-control-bitstringactuator,
aes70-control-bitstringsensor {
  display: flex;
  flex-direction: column;
}
aes70-control-bitstringactuator > .aux-label,
aes70-control-bitstringsensor > .aux-label {
  margin-bottom: 4px;
  align-self: flex-start;
}
aes70-control-bitstringactuator > .aux-buttons,
aes70-control-bitstringsensor > .aux-buttons {
  display: grid;
  grid-gap: 2px;
}
aes70-control-bitstringactuator > .aux-buttons > .aux-button,
aes70-control-bitstringsensor > .aux-buttons > .aux-button {
  padding: 0;
  margin: 0 !important;
}
aes70-control-bitstringactuator > .aux-buttons.aes70-size-large > .aux-button,
aes70-control-bitstringsensor > .aux-buttons.aes70-size-large > .aux-button {
  min-width: 40px;
  height: 40px;
}
aes70-control-bitstringactuator > .aux-buttons.aes70-size-medium > .aux-button,
aes70-control-bitstringsensor > .aux-buttons.aes70-size-medium > .aux-button {
  min-width: 32px;
  height: 32px;
}
aes70-control-bitstringactuator > .aux-buttons.aes70-size-small > .aux-button,
aes70-control-bitstringsensor > .aux-buttons.aes70-size-small > .aux-button {
  min-width: 24px;
  height: 24px;
}
aes70-control-bitstringactuator > .aux-buttons.aes70-size-tiny > .aux-button,
aes70-control-bitstringsensor > .aux-buttons.aes70-size-tiny > .aux-button {
  min-width: 16px;
  height: 16px;
}


aes70-control-bitstringsensor {
  pointer-events: none;
}


aes70-control-booleansensor {
  grid-area: trig;
  display: flex;
  flex-direction: column;
  align-items: center !important;
}
aes70-control-booleansensor > .aux-state {
  margin-top: 8px;
}


aes70-control-dynamics {
  display: grid !important;
  grid-template-areas: "label label label label label label label label"
                       "hold attack graph graph meter thres knee ceil"
                       ". release graph graph meter slope . floor"
                       "trig release func detect . slope . floor";
    grid-gap: 4px 4px;
}
aes70-control-dynamics > .aux-label {
  grid-area: label;
}
aes70-control-dynamics > .aux-dynamics {
  grid-area: graph;
  width: 160px;
  height: 160px;
}
aes70-control-dynamics > .triggered {
  grid-area: trig;
  display: flex;
  flex-direction: column;
  align-items: center;
}
aes70-control-dynamics > .triggered > .aux-state {
  margin-top: 8px;
}

aes70-control-dynamics > .aux-levelmeter {
  grid-area: meter;
  height: 160px;
}

aes70-control-dynamics > .attack {
  grid-area: attack;
}
aes70-control-dynamics > .release {
  grid-area: release;
}
aes70-control-dynamics > .hold {
  grid-area: hold;
}

aes70-control-dynamics > .threshold {
  grid-area: thres;
}
aes70-control-dynamics > .slope {
  grid-area: slope;
}
aes70-control-dynamics > .knee {
  grid-area: knee;
}

aes70-control-dynamics > .gainceiling {
  grid-area: ceil;
}
aes70-control-dynamics > .gainfloor {
  grid-area: floor;
}

aes70-control-dynamics > .function {
  grid-area: func;
}
aes70-control-dynamics > .detect {
  grid-area: detect;
}


aes70-control-dynamicsdetector {
  display: grid !important;
  grid-template-areas: "label label label"
                       "attack release hold"
                       ". . law";
    grid-gap: 8px 0px;
}
aes70-control-dynamicsdetector > .aux-label {
  grid-area: label;
}
aes70-control-dynamicsdetector > .attack {
  grid-area: attack;
}
aes70-control-dynamicsdetector > .release {
  grid-area: release;
}
aes70-control-dynamicsdetector > .hold {
  grid-area: hold;
}
aes70-control-dynamicsdetector > .law {
  grid-area: law;
}


aes70-control-filterclassical {
  display: grid !important;
  grid-template-areas:
    "label label label"
    "param freq order"
    "switch switch switch";
  grid-gap: 8px 0;
}
aes70-control-filterclassical > .aux-label {
  grid-area: label;
  text-align: left;
}
aes70-control-filterclassical > .order > .aux-valueknob > .aux-knob {
  margin-top: 0;
}
aes70-control-filterclassical > .order > .aux-valueknob > .aux-knob .aux-label {
  font-size: 7px !important;
}
aes70-control-filterclassical > .switch {
  grid-area: switch;
}


aes70-control-filterfir {
  display: grid !important;
  grid-template-areas: "label label label"
                       "file file file"
                       "resp resp resp"
                       ". lsr sr";
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center !important;
}
aes70-control-filterfir > .aux-label.label {
  grid-area: label;
}
aes70-control-filterfir > .aux-label.srate {
  grid-area: lsr;
  justify-self: end;
}
aes70-control-filterfir > .aux-value {
  grid-area: sr;
}
aes70-control-filterfir > .aux-label.sfx {
  grid-area: sfx;
  justify-self: start;
}
aes70-control-filterfir > .aux-fileselect {
  grid-area: file;
  display: flex;
  width: 320px;
}
aes70-control-filterfir > .aux-fileselect > .aux-name {
  flex: 1 1 auto;
}
aes70-control-filterfir > .aux-frequencyresponse {
  grid-area: resp;
  width: 320px;
  height: 128px;
}


aes70-control-filterparametric {
  display: grid !important;
  grid-template-areas:
    "label label label label"
    "width freq gain shapep"
    "shape shape shape shape";
  grid-gap: 8px 0;
}
aes70-control-filterparametric > .aux-label {
  grid-area: label;
  text-align: left;
}
aes70-control-filterparametric > .freq {
}
aes70-control-filterparametric > .gain {
  grid-area: gain;
}
aes70-control-filterparametric > .width {
  grid-area: width;
}
aes70-control-filterparametric > .shapep {
  grid-area: shapep;
}
aes70-control-filterparametric > .shape {
  grid-area: shape;
}
aes70-control-filterparametric > .shape > .aux-button {
  float: right;
}


aes70-control-gain {
  height: 240px;
}
aes70-control-gain > .aux-fader {
  height: 100%;
}


aes70-control-identificationactuator,
aes70-control-mute,
aes70-control-polarity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
aes70-control-identificationactuator > .aux-toggle,
aes70-control-mute > .aux-toggle,
aes70-control-polarity > .aux-toggle {
  padding-left: var(--aux-button-paddinghorizontal) !important;
  margin-top: 8px;
}
aes70-control-identificationactuator > .aux-toggle::before,
aes70-control-mute > .aux-toggle::before,
aes70-control-polarity > .aux-toggle::before {
  content: none !important;
}
aes70-control-identificationactuator > .aux-toggle > .aux-icon,
aes70-control-mute > .aux-toggle > .aux-icon,
aes70-control-polarity > .aux-toggle > .aux-icon {
  font-size: 20px;
}
aes70-control-identificationactuator > .aux-toggle.aux-active > .aux-icon,
aes70-control-mute > .aux-toggle.aux-active > .aux-icon,
aes70-control-polarity > .aux-toggle.aux-active > .aux-icon {
  color: var(--color-accent);
}


aes70-control-timeintervalsensor,
aes70-control-levelsensor,
aes70-control-floatsensor,
aes70-control-gainsensor,
aes70-control-intsensor {
  height: 240px;
}
aes70-control-timeintervalsensor > .aux-meter,
aes70-control-levelsensor > .aux-meter,
aes70-control-floatsensor > .aux-meter,
aes70-control-gainsensor > .aux-meter,
aes70-control-intsensor > .aux-meter {
  height: 100%;
}


aes70-control-signalgenerator {
  display: grid !important;
  grid-template-areas: "label label label label label"
                       "freq1 freq2 level time type"
                       "freq1 freq2 level time repeat"
                       "wave wave wave wave toggle";
  grid-gap: 8px 2px;
}
aes70-control-signalgenerator > .aux-label {
  grid-area: label;
}
aes70-control-signalgenerator > .freq1 {
  grid-area: freq1;
}
aes70-control-signalgenerator > .freq2 {
  grid-area: freq2;
}
aes70-control-signalgenerator > .level {
  grid-area: level;
}
aes70-control-signalgenerator > .sweeptime {
  grid-area: time;
}
aes70-control-signalgenerator > .sweeptype {
  grid-area: type;
  display: flex;
  flex-direction: column;
  align-items: center;
}
aes70-control-signalgenerator > .sweeptype > .aux-select {
  width: 100%;
  margin-top: 8px;
}
aes70-control-signalgenerator > .sweeprepeat {
  grid-area: repeat;
  width: fit-content;
}
aes70-control-signalgenerator > .waveform {
  grid-area: wave;
}
aes70-control-signalgenerator > .startstop {
  grid-area: toggle;
  text-align: right;
}
aes70-control-signalgenerator > .startstop > * {
  padding: 0;
  width: var(--aux-clickable-size);
}
aes70-control-signalgenerator > .startstop > .start {
  color: var(--color-accent);
}
aes70-control-signalgenerator > .startstop > .stop {
  background: var(--color-accent);
  color: var(--color-contrast);
}

aes70-control-stringactuator {
  display: flex;
  flex-direction: column;
}
aes70-control-stringactuator > .aux-label {
  align-self: flex-start;
  margin-bottom: 4px;
}
aes70-control-stringactuator > .aux-value {
  width: 192px;
  text-align: left;
}


aes70-control-frequencysensor,
aes70-control-stringsensor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
aes70-control-frequencysensor > .aux-label.value,
aes70-control-stringsensor > .aux-label.value {
  margin-top: 8px;
  font-size: var(--font-size-large);
}
aes70-control-frequencysensor > .aux-label.value::after {
  content: "Hz";
}


aes70-control-switch {
  display: flex;
  flex-direction: column;
  align-items: center !important;
}
aes70-control-switch > .aux-label {
  align-self: flex-start;
  margin-bottom: 4px;
}


aes70-control-temperaturesensor {
  display: grid !important;
  grid-template-areas: "label label"
                       "icon temp";
  grid-gap: 8px;
}
aes70-control-temperaturesensor > .aux-label.label {
  grid-area: label;
}
aes70-control-temperaturesensor > .aux-icon {
  grid-area: icon;
  justify-self: end;
  align-self: center;
  font-size: var(--font-size-button-icon-small);
}
aes70-control-temperaturesensor > .aux-label.temp {
  grid-area: temp;
  justify-self: start;
  align-self: center;
  font-size: var(--font-size-large);
}



.aux-gauge.aux-preset-gauge {
  width: 128px;
  height: 56px;
}
.aux-gauge.aux-preset-phase {
  width: 48px;
  height: 48px;
}
.aux-gauge.aux-preset-gauge > svg {
  width: 128px;
  height: 128px;
}
.aux-gauge.aux-preset-phase > svg {
  width: 40px;
  height: 40px;
}

.aux-gauge.aux-gauge > svg > .aux-circular > .aux-hand {
  fill: var(--color);
}
.aux-gauge.aux-gauge > svg > .aux-circular > .aux-hand {
  fill: var(--color);
}
.aux-gauge.aux-gauge > svg > .aux-circular > .aux-base {
  fill: var(--background-dark);
  stroke: none;
}
.aux-gauge.aux-gauge > svg > .aux-circular > .aux-value {
  fill: var(--color-accent);
  stroke: none;
}

aes70-control-currentsensor,
aes70-control-voltagesensor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
aes70-control-currentsensor > .aux-label.value,
aes70-control-voltagesensor > .aux-label.value {
  font-size: var(--font-size-large);
}

aes70-control-impedancesensor {
  display: grid !important;
  grid-template-areas: "label label"
                      "impg phaseg"
                      "impv phaser"
                      "impv phased";
  grid-gap: 0 16px;
  align-items: center;
}
aes70-control-impedancesensor > .label {
  grid-area: label;
}
aes70-control-impedancesensor > .magnitudegauge {
  grid-area: impg;
  
}
aes70-control-impedancesensor > .magnitudevalue {
  grid-area: impv;
  font-size: var(--font-size-large);
  
}
aes70-control-impedancesensor > .phasegauge {
  grid-area: phaseg;
  
}
aes70-control-impedancesensor > .phaserad {
  grid-area: phaser;
}
aes70-control-impedancesensor > .phasedeg {
  grid-area: phased;
}




aes70-device-control-generic {
  width: 100vw;
  text-align: left;
  border-bottom: 1px solid var(--color-accent);
}
aes70-device-control-generic > .manufacturer {
  font-size: var(--font-size-huge);
  color: var(--color-accent);
  margin-right: 8px;
}
aes70-device-control-generic > .label {
  font-size: var(--font-size-large);
  margin-right: 8px;
}
aes70-device-control-generic > .version {
  color: var(--color-fade);
}
aes70-device-control-generic > .version::before {
  content: "v";
}
aes70-device-control-generic > .serial {
  text-align: right;
  color: var(--color-fade);
  float: right;
}
aes70-device-control-generic > .serial::before {
  content: "#";
}
