:root {
    
  --font-size-tiny: 10px;
  --font-size-small: 12px;
  --font-size: 15px;
  --font-size-large: 18px;
  --font-size-huge: 22px;
  --font-size-gigantic: 40px;
  
  --font-size-list-icon: 26px;
  --font-size-button-icon-small: 24px;
  --font-size-button-icon: 32px;
  
  --line-height: 1.2em;
  
  --border-radius: 3px;
  
  --transition: 0.33s;
  
  --background: #232635;
  --background-fade: rgba(0, 0, 0, 0.3);
  --background-medium: #181B25;
  --background-dark: #101012;
  --background-even: #0d0d0e;
  --background-odd: #121214;
  --background-selected: #2C2F3D;
  --background-control: #1e2230;
  --background-transparent: rgba(0, 0, 0, 0.0);
  
  --color: #ffffff;
  --color-orange: #ff6600;
  --color-violet: #a31a83;
  --color-green: #17f575;
  --color-dark: #87878B;
  --color-fade: rgba(255, 255, 255, 0.33); /*#4e505e; /*#6e707e;*/
  --color-accent: var(--color-orange);
  --color-contrast: var(--background-dark);
  --color-warn: var(--color-violet);
  --color-ok: var(--color-green);
  --color-error: var(--color-orange);
  
  --color-shadow: rgba(0, 0, 0, 0.8);
  
  --bordercolor-control-selected: rgba(255,255,255,0.1);
  --border-control-selected: 1px solid var(--bordercolor-control-selected);
  
  
  --link-color: var(--color-accent);
  --link-hover-color: var(--color);
  --link-visited-color: var(--color-warn);
  
  
  --button-background: var(--background-dark);
  --button-nav-background: var(--background-transparent);
  --button-color: var(--color);
  --button-hover-background: var(--background-dark);
  --button-hover-color: var(--color-accent);
  --button-active-background: var(--color-accent);
  --button-active-color: var(--color-contrast);
  
  --charthandle-color: rgba(255,255,255,0.2);
  
  --fader-track-horizontal-background: repeating-linear-gradient(to right, black 0px, black 1px, transparent 1.001px, transparent 2px);
  --fader-track-vertical-background: repeating-linear-gradient(to bottom, black 0px, black 1px, transparent 1.001px, transparent 2px);
  --fader-handle-horizontal-background: linear-gradient(to right, #e9deff 0%, #858585 11%, #e3e3ff 50%, #ffffff 89%, #5e5154 100%);
  --fader-handle-vertical-background: linear-gradient(to bottom, #e9deff 0%, #858585 11%, #e3e3ff 50%, #ffffff 89%, #5e5154 100%);
  --fader-handle-bar: var(--color-accent);
  --fader-handle-shadow: 0 3px 8px var(--color-shadow);
  
  --graph-color: var(--color-accent);
  --graph-color: var(--color-accent);
  
  --grid-label-color: #67676A;
  
  --toggle-background: rgba(0, 0, 0, 0.34);
  --toggle-color: var(--color-fade);
  --toggle-hover-background: var(--background-dark);
  --toggle-hover-color: var(--color);
  --toggle-active-background: var(--background-dark);
  --toggle-active-color: var(--color);
  --toggle-indicator: var(--color-fade);
  --toggle-hover-indicator: var(--color);
  --toggle-active-indicator: var(--color-accent);
}
